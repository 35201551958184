import React from "react"
import { graphql } from "gatsby"
import { Box, Grid, Text, Heading } from "theme-ui"
import { GatsbyImage } from "jam-cms"
import Parser from "html-react-parser"

// import app components
import Edges from "../../components/Edges"
import Button from "../../components/Button"
import Layout from "../../components/Layout"
import Textarea from "../../components/Textarea"

const FacilityPageTemplate = (props) => {
  const {
    data: {
      wpPage: {
        template: {
          seo,
          acf: { section1, section2, section3, section4, section5 },
        },
      },
    },
  } = props

  return (
    <Layout {...props} seo={seo}>
      {/*
      Section 1 -----------------------------------------------------------------------------------------------
      */}
      <Box
        sx={{
          display: "flex",
          width: "100%",
          minHeight: "100%",
        }}
      >
        <Grid
          sx={{
            position: "relative",
            height: "100%",
            gridRow: 1,
            gap: 0,

            width: "100%",
          }}
          columns={[1, "1fr 1fr", "1fr 1fr"]}
        >
          <Box
            sx={{
              position: "relative",

              bg: "black",
              gridRowStart: [1, 1],
              gridColumnStart: 1,
              width: "100%",
              display: "flex",
              height: ["300px", "400px", "450px"],
            }}
          >
            {section1?.headline && (
              <Heading
                variant="text.sectionHeading"
                sx={{
                  mt: "auto",
                  mr: "auto",
                  ml: [4, 5, 6],
                  mb: 5,
                }}
              >
                {Parser(section1?.headline)}
              </Heading>
            )}
          </Box>

          <Box
            sx={{
              position: "relative",
              gridRowStart: [2, 1],
              gridColumnStart: [1, 2],
              width: "100%",
              height: ["300px", "400px", "450px"],
              mb: ["5px", 0],
              ".gatsby-image-wrapper": {
                width: "100%",
                height: ["300px", "400px", "450px"],
              },
            }}
          >
            {section1?.image1 && (
              <GatsbyImage
                image={section1?.image1}
                alt={section1?.image1?.altText}
                objectPosition="0"
                objectFit="cover"
                className="gatsbyImage"
              />
            )}
          </Box>

          <Box
            sx={{
              position: "relative",
              bg: "white",
              gridRowStart: [4, 2],
              gridColumnStart: [1, 2],
              width: "100%",
              height: "100%",
              minHeight: ["300px", "600px"],
              pt: ["30px", "90px"],
              pl: ["30px", "45px"],
              pr: ["30px", "105px"],
              pb: ["30px", "90px"],
            }}
          >
            {section1?.text && <Textarea content={section1?.text} />}
          </Box>
          <Box
            sx={{
              position: "relative",
              gridRowStart: [3, 2],
              gridColumnStart: 1,
              width: "100%",
              height: ["300px", "400px", "450px"],
              ".gatsby-image-wrapper": {
                width: "100%",
                height: ["300px", "400px", "450px"],
              },
            }}
          >
            {section1?.image2 && (
              <GatsbyImage
                image={section1?.image2}
                alt={section1?.image2?.altText}
                objectPosition="0"
                objectFit="cover"
                className="gatsbyImage"
              />
            )}
          </Box>
        </Grid>
      </Box>
      {/*
      Section 2-----------------------------------------------------------------------------------------------
      */}

      <Box>
        <Grid sx={{ gap: 0 }} columns={[1, "1fr 1fr", "1fr 1fr"]}>
          <Box
            sx={{
              position: "relative",
              bg: "white",
              gridRowStart: [2, 1],
              gridColumnStart: [1, 2],
              width: "100%",
              height: "100%",
              display: "flex",
              minHeight: "300px",
              my: "auto",
              alignItems: "center",
              pl: ["30px", "40px"],
              pr: ["30px", "106px"],
              pt: ["50px", "100px"],
              pb: ["50px", 0],
            }}
          >
            {section2?.text1 && <Textarea content={section2?.text1} />}
          </Box>
          <Box
            bg={"black"}
            sx={{
              position: "relative",
              pt: [0, "50px", "100px"],
              pl: [0, "106px"],
              gridRowStart: 1,
              gridColumnStart: 1,
              width: "100%",
              ".gatsby-image-wrapper": {
                width: "100%",
                height: "100%",
                maxHeight: "478px",
              },
            }}
          >
            {section2?.image1 && (
              <GatsbyImage
                image={section2?.image1}
                alt={section2?.image1?.altText}
                objectPosition="0"
                objectFit="cover"
                className="gatsbyImage"
              />
            )}
          </Box>
          <Box
            sx={{
              position: "relative",
              bg: "white",
              gridRowStart: [4, 2],
              gridColumnStart: [1, 2],
              width: "100%",
              height: "100%",
              display: "flex",
              minHeight: "300px",
              my: "auto",
              alignItems: "center",
              pb: ["50px", "100px"],
              pt: ["25px", "50px"],
              pl: ["30px", "40px"],
              pr: ["30px", "106px"],
            }}
          >
            {section2?.text2 && <Textarea content={section2?.text2} />}
          </Box>
          <Box
            bg={"black"}
            sx={{
              position: "relative",
              pl: [0, "106px"],
              pb: [0, "100px"],
              pt: [0, "50px"],
              gridRowStart: [3, 2],
              gridColumnStart: 1,
              width: "100%",
              ".gatsby-image-wrapper": {
                width: "100%",
                height: "100%",
                maxHeight: "478px",
              },
            }}
          >
            {section2?.image2 && (
              <GatsbyImage
                image={section2?.image2}
                alt={section2?.image2?.altText}
                objectPosition="0"
                objectFit="cover"
                className="gatsbyImage"
              />
            )}
          </Box>
        </Grid>
      </Box>
      {/*
      Section 3-----------------------------------------------------------------------------------------------
      */}
      <Box bg={"white"} sx={{ mb: "30px" }}>
        <Box
          sx={{
            display: ["flex", "grid"],
            flexDirection: "column",
            gridTemplateColumns: [1, "1fr 1fr 1fr 1fr 1fr 1fr"],
            gridTemplateRows: [null, "1fr 1fr 1fr 1fr 1fr 1fr 1fr"],
            gap: [0, 0],
          }}
        >
          <Box
            sx={{
              gridRowStart: 1,
              gridRowEnd: 4,
              gridColumnStart: 1,
              gridColumnEnd: 4,
              width: "100%",

              ".gatsby-image-wrapper": {
                width: "100%",
                height: ["300px", "100%"],
              },
            }}
          >
            {section3.image1 && (
              <GatsbyImage
                image={section3.image1}
                alt={section3.image1?.altText}
                objectPosition="0"
                objectFit="cover"
                className="gatsbyImage"
              />
            )}
          </Box>
          <Box
            sx={{
              gridRowStart: 1,
              gridRowEnd: 3,
              gridColumnStart: 4,
              gridColumnEnd: 6,
              pl: [0, "5px", "5px"],
              pb: [0, "5px", "5px"],
              width: "100%",
              ".gatsby-image-wrapper": {
                width: "100%",
                height: ["300px", "100%"],
              },
            }}
          >
            {section3.image2 && (
              <GatsbyImage
                image={section3.image2}
                alt={section3.image2?.altText}
                objectPosition="0"
                objectFit="cover"
                className="gatsbyImage"
              />
            )}
          </Box>
          <Box
            sx={{
              gridRowStart: 3,
              gridRowEnd: 5,
              gridColumnStart: 4,
              gridColumnEnd: 6,
              pl: [0, "5px", "5px"],
              pb: [0, "5px", "5px"],
              width: "100%",
              ".gatsby-image-wrapper": {
                width: "100%",
                height: ["300px", "100%"],
              },
            }}
          >
            {section3.image3 && (
              <GatsbyImage
                image={section3.image3}
                alt={section3.image3?.altText}
                objectPosition="0"
                objectFit="cover"
                className="gatsbyImage"
              />
            )}
          </Box>
          <Box
            sx={{
              gridRowStart: 5,
              gridRowEnd: 7,
              gridColumnStart: 4,
              gridColumnEnd: 6,
              pl: [0, "5px", "5px"],
              pb: [0, "5px", "5px"],
              width: "100%",
              ".gatsby-image-wrapper": {
                width: "100%",
                height: ["300px", "100%"],
              },
            }}
          >
            {section3.image4 && (
              <GatsbyImage
                image={section3.image4}
                alt={section3.image4?.altText}
                objectPosition="0"
                objectFit="cover"
                className="gatsbyImage"
              />
            )}
          </Box>
          <Box
            sx={{
              gridRowStart: 3,
              gridRowEnd: 5,
              gridColumnStart: 6,
              gridColumnEnd: 7,
              width: "100%",
              pb: [0, "5px", "5px"],
              pl: [0, "5px", "5px"],
              ".gatsby-image-wrapper": {
                width: "100%",
                height: ["300px", "100%"],
              },
            }}
          >
            {section3.image5 && (
              <GatsbyImage
                image={section3.image5}
                alt={section3.image5?.altText}
                objectPosition="0"
                objectFit="cover"
                className="gatsbyImage"
              />
            )}
          </Box>
          <Box
            sx={{
              gridRowStart: 4,
              gridRowEnd: 8,
              gridColumnStart: 1,
              gridColumnEnd: 4,
              width: "100%",
              bg: "white",
              height: "100%",
              display: "flex",
              minHeight: "300px",
              pt: ["30px", "30px", "90px"],
              pr: ["30px", "30px", "40px"],
              pl: ["30px", "30px", "106px"],
              pb: "30px",
            }}
          >
            {section3?.text && <Textarea content={section3?.text} />}
          </Box>
        </Box>
      </Box>

      {/*
        Section 4-----------------------------------------------------------------------------------------------
        */}
      <Box bg={"black"} py={["40px", "60px"]}>
        <Edges size="sm">
          <Grid column={[1, "50% 50%"]}>
            <Box
              sx={{
                gridColumnStart: 1,
                gridRowStart: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                flexDirection: "column",
                mt: "20px",
              }}
            >
              {section4?.headline && (
                <Heading
                  color="white"
                  sx={{
                    letterSpacing: "1px",
                    fontWeight: 500,
                    fontSize: [7, 8, 14],
                    pb: "24px",
                  }}
                  children={section4?.headline}
                />
              )}
              {section4?.text && (
                <Text
                  color="white"
                  sx={{
                    fontWeight: 400,
                    fontSize: [2, 3],
                  }}
                  children={section4?.text}
                />
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gridColumnStart: [1, 2],
                gridRowStart: [2, 1],
              }}
            >
              {section4?.link?.url && (
                <Box
                  sx={{
                    mx: "auto",
                  }}
                >
                  <Button to={section4?.link.url}>
                    {section4?.link.title}
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>
        </Edges>
      </Box>
      {/*
      Section 5-----------------------------------------------------------------------------------------------
      */}
      <Box
        bg={"black"}
        sx={{
          pb: ["60px", "80px"],
        }}
      >
        <Box
          sx={{
            display: ["flex", "grid"],
            flexDirection: "column",
            gridTemplateColumns: [1, "1fr 1fr 1fr 1fr 1fr 1fr"],
            gridTemplateRows: [null, "1fr 1fr 1fr 1fr"],
            gap: [0, "5px"],
          }}
        >
          <Box
            sx={{
              gridRowStart: 1,
              gridRowEnd: 4,
              gridColumnStart: 1,
              gridColumnEnd: 4,
              width: "100%",
              ".gatsby-image-wrapper": {
                width: "100%",
                height: ["300px", "100%"],
              },
            }}
          >
            {section5.image1 && (
              <GatsbyImage
                image={section5.image1}
                alt={section5.image1?.altText}
                objectPosition="0"
                objectFit="cover"
                className="gatsbyImage"
              />
            )}
          </Box>
          <Box
            sx={{
              gridRowStart: 1,
              gridRowEnd: 3,
              gridColumnStart: 4,
              gridColumnEnd: 6,
              width: "100%",
              ".gatsby-image-wrapper": {
                width: "100%",
                height: ["300px", "100%"],
              },
            }}
          >
            {section5.image2 && (
              <GatsbyImage
                image={section5.image2}
                alt={section5.image2?.altText}
                objectPosition="0"
                objectFit="cover"
                className="gatsbyImage"
              />
            )}
          </Box>
          <Box
            sx={{
              gridRowStart: 3,
              gridRowEnd: 5,
              gridColumnStart: 4,
              gridColumnEnd: 6,
              width: "100%",
              ".gatsby-image-wrapper": {
                width: "100%",
                height: ["300px", "100%"],
              },
            }}
          >
            {section5.image3 && (
              <GatsbyImage
                image={section5.image3}
                alt={section5.image3?.altText}
                objectPosition="0"
                objectFit="cover"
                className="gatsbyImage"
              />
            )}
          </Box>

          <Box
            sx={{
              gridRowStart: 3,
              gridRowEnd: 5,
              gridColumnStart: 6,
              gridColumnEnd: 7,
              width: "100%",
              ".gatsby-image-wrapper": {
                width: "100%",
                height: ["300px", "100%"],
              },
            }}
          >
            {section5.image4 && (
              <GatsbyImage
                image={section5.image4}
                alt={section5.image4?.altText}
                objectPosition="0"
                objectFit="cover"
                className="gatsbyImage"
              />
            )}
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}

export const CollectionQuery = graphql`
  query FacilityPage($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      uri
      seo {
        title
        metaDesc
        opengraphImage {
          sourceUrl
        }
      }
      template {
        ... on WpTemplate_Facility {
          templateName
          acf {
            fieldGroupName
            section1 {
              fieldGroupName
              headline
              text
              image1 {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 750, quality: 100)
                  }
                }
              }
              image2 {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 750, quality: 100)
                  }
                }
              }
            }
            section2 {
              fieldGroupName
              text1
              text2
              image1 {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 750, quality: 100)
                  }
                }
              }
              image2 {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 750, quality: 100)
                  }
                }
              }
            }

            section3 {
              fieldGroupName
              text
              image1 {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 750, quality: 100)
                  }
                }
              }
              image2 {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 500, quality: 100)
                  }
                }
              }
              image3 {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 500, quality: 100)
                  }
                }
              }
              image4 {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 500, quality: 100)
                  }
                }
              }
              image5 {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 500, quality: 100)
                  }
                }
              }
            }
            section4 {
              fieldGroupName
              text
              headline
              link {
                target
                title
                url
              }
            }
            section5 {
              image1 {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 750, quality: 100)
                  }
                }
              }
              image2 {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 500, quality: 100)
                  }
                }
              }
              image3 {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 500, quality: 100)
                  }
                }
              }
              image4 {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 500, quality: 100)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default FacilityPageTemplate
